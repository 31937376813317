import React, { useEffect } from 'react';
import Footer from "../Footer";

const Main = () => {
  // const history = useHistory();

  const speakers = [
    {
      name: "Vaidya M. R. Vasudevan Nampoothiri",
      topic: "Rasayana chikitsa- Some Clinical Experiences",
    },
    {
      name: "Vaidya. Shri Krishna Sharma Khandel",
      topic: "Manorog in Ayurved: Concept to Clinic",
    },
    {
      name: "Vaidya M.Gopikrishna",
      topic:
        "Pottali and Sindoor Kalpana in management of Critical conditions: Clinical Experiences",
    },
    {
      name: "Vaidya Mamatha K.V.",
      topic: "Ayurvedic Management of PCOD",
    },
    {
      name: "Vaidya Sreekanth P Namboothiri",
      topic:
        "Management of Diabetic Retinopathy and Other Ocular Pathologies Through Ayurveda: Insights from Clinical Practice",
    },
    {
      name: "Vaidya Mahesh Birla",
      topic: "Panchakarma Protocols for Successful Ayurvedic Practise",
    },
    {
      name: "Vaidya Mrityunjay Sharma",
      topic:
        "Comprehensive approach for ayurvedic management of Spine Disorders",
    },
    {
      name: "Vd Samir Jamadagni",
      topic: "Scope of Ayurveda in Management of Cancer",
    },
  ];
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdn.razorpay.com/static/embed_btn/bundle.js';
    script.id = 'razorpay-embed-btn-js';
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (

        <div>
          <nav className="h-16 sm:h-45 md:h-45 bg-custom flex flex-row w-full gap-8 p-2 justify-between shadow-md items-center">
            <h1 className="text-sm font-bold md:text-lg text-white ml-5">
              Shri Vishwa Vyakhyan Mala 2024
            </h1>
            
          </nav>
          <section className="p-4">
            <h2 className="font-bold text-xl mb-2">
              Welcome 
            </h2>
            <div className="flex justify-end">
            <div class="razorpay-embed-btn" data-url="https://pages.razorpay.com/pl_OEPYGlWVyGkQvZ/view" data-text="Register" data-color="#528FF0" data-size="medium">
</div>

    
            </div>
            <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-xl mb-2">
                Three days National Seminar On Ayurveda
              </h2>

              <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>

                <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
                  <h2 className="font-bold text-xl mb-2">Event Details</h2>
                  <p>Revisiting Siddhantas - Exploring Practices</p>
                  <p>🗓️ Date : 20, 21 & 22 September 2024</p>
                  <p>⏰ Time : 8 am to 6pm</p>
                  <p>
                    📍Venue : Kavivarya Suresh Bhat Sabhagruh, Great Nag Road,
                    Reshim baug, Nagpur.
                  </p>
                </div>
                <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
                  <h2 className="font-bold text-xl mb-2">
                    🔰 Registration charges 🔰
                  </h2>
                  <p>
                    (Includes: 1 Welcome Kit + Seminar Entry + Breakfast + Lunch
                    + High Tea for 2 days)
                  </p>
                  <p>▪️UG students & Interns : 1200/-</p>
                  <p>▪️PG students : ₹ 1500/-</p>
                  <p>▪️PhD Scholars/ Teachers/ Practitioners/Paramedical : ₹1800/-</p>
                  <p>
                    ▪️ Conference with Presentation (Paper/poster) : 1800/-
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="p-4">
            <div className="rounded overflow-hidden shadow-lg p-4 bg-white">
              <h2 className="font-bold text-2xl mb-4">Speakers</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {speakers.map((speaker, index) => (
                  <div
                    key={index}
                    className="rounded overflow-hidden shadow-lg p-4 bg-white"
                  >
                    <div className="font-bold text-xl mb-2">{speaker.name}</div>
                    <p className="text-gray-700 text-base">{speaker.topic}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <div>
          </div>
          <Footer/>
        </div>
    
  );
};

export default Main;
