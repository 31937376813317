import { Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Cancellation from "./components/Cancellation";
function App() {
  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/policy" exact element={<Cancellation />} />
        <Route path="/" exact element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
